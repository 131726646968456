<template>
<section class="container parcours" id="parcours">
            <div class="titre-page">
                <h2>Mon Parcours</h2>
            </div>
            <h4>Mes compétences</h4>
            <div class="parcours-competences">
                <div class="competences-developpement">
                    <h5>Développement</h5>
                    <p>HTML5</p>
                    <p>CSS3</p>
                    <p>PHP5</p>
                    <p>MySQL</p>
                    <p>Javascript</p>
                    <p>Drupal</p>
                    <p>Java</p>
                    <p>NodeJS</p>
                    <p>VueJS</p>
                    <p>Programmation Orientée Objet</p>
                </div>
                <div class="competences-infographie">
                    <h5>Infographie</h5>
                    <p>Photoshop</p>
                    <p>Illustrator</p>
                    <p>GIMP</p>
                    <p>Inkscape</p>
                    <p>Scribus</p>
                    <p>Blender</p>
                    <p>Avid</p>
                    <p>Premiere</p>
                </div>
            </div>
            <h4>Mes Diplomes</h4>
            <div class="parcours-diplomes">
                <div class="timeline-item">
                    <div class="tl-icon">
                        <ion-icon name="school"></ion-icon>
                    </div>
                    <p class="tl-annee">2015</p>
                    <h5>Licence Communication Libre et Sources Ouvertes<span>ICOM - Université lyon 2</span></h5>
                </div>
                <div class="timeline-item">
                    <div class="tl-icon">
                        <ion-icon name="school"></ion-icon>
                    </div>
                    <p class="tl-annee">2013</p>
                    <h5>DUT Services et réseaux de communication (équivalent MMI)<span>IUT de Laval</span></h5>
                </div>
                <div class="timeline-item">
                    <div class="tl-icon">
                        <ion-icon name="school"></ion-icon>
                    </div>
                    <p class="tl-annee">2011</p>
                    <h5>Baccalauréat Scientifique - mention assez bien<span>Lycée Le Fresne Angers</span></h5>
                </div>
            </div>
            <h4>Mes expériences professionelles en développement</h4>
            <div class="parcours-experiences-dev">
                <div class="timeline-item">
                    <div class="tl-icon">
                        <ion-icon name="briefcase"></ion-icon>
                    </div>
                    <p class="tl-annee">2015</p>
                    <h5>Stage en développement web pour le projet MIRIADI.<span>4 mois</span></h5>
                    <p>- Développement sous Drupal</p>
                    <p>- Mise en place d'un outil de formations</p>
                    <p>- Adaptation de plugins Drupal</p>
                </div>
                <div class="timeline-item">
                    <div class="tl-icon">
                        <ion-icon name="briefcase"></ion-icon>
                    </div>
                    <p class="tl-annee">2013</p>
                    <h5>Développement d'un jeu de plate-forme sous Unity dans le cadre d'un projet tutoré.<span>3 semaines</span></h5>
                    <p>- Travail en équipe</p>
                    <p>- Gestion de projet</p>
                    <p>- Développement en Javascript</p>
                </div>
                <div class="timeline-item">
                    <div class="tl-icon">
                        <ion-icon name="briefcase"></ion-icon>
                    </div>
                    <p class="tl-annee">2013</p>
                    <h5>Stage en développement Web dans l'agence de communication Web2C.<span>2 mois</span></h5>
                    <p>- Adaptation de CMS Wordpress</p>
                    <p>- Développement spécifique avec CakePHP</p>
                    <p>- Maquettage et intégration de sites web</p>
                </div>
                <div class="timeline-item">
                    <div class="tl-icon">
                        <ion-icon name="briefcase"></ion-icon>
                    </div>
                    <p class="tl-annee">2013</p>
                    <h5>Stage en développement Web pour Angers Technopole.<span>2 semaines</span></h5>
                    <p>- Mise en place d'un site vitrine pour l'espace Angers Coworking</p>
                    <p>- Adaptation de maquettes pré-établies et suivi d'un cahier des charges</p>
                    <p>- Développement front-end et développement spécifique de plug-ins Wordpress</p>
                </div>
            </div>
            <div class="parcours-experiences-autre">
                <h4>Mes autres expériences</h4>
                <h5>Manutention dans une entreprise de façonnage de carrelage <span>6 mois</span></h5>
                <h5>Accueil/ventes dans un parc pour enfants <span>6 mois</span></h5>
            </div>
            <div class="forme"></div>
        </section>
</template>